
import { defineComponent } from "vue";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  alertController,
} from "@ionic/vue";

import { useStore } from "@/store";
import { SET_USER_PARTIAL } from "@/store/mutations";

export default defineComponent({
  name: "Options",
  components: {
    IonToggle,
    IonItemGroup,
    IonItem,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItemDivider,
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
  },
  setup() {
    const store = useStore();
    return {
      saveOptions(key: string, value: boolean) {
        store.commit(SET_USER_PARTIAL, { [key]: value });
        store.dispatch("saveOptions");
      },
      async clearLogs() {
        const alert = await alertController.create({
          header: "Confirmer!",
          message: "Supprimer données logs du cache?",
          buttons: [
            {
              text: "Annuler",
              role: "cancel",
            },
            {
              text: "Supprimer",
              handler: () => {
                store.dispatch("clearLogs");
              },
            },
          ],
        });
        return alert.present();
      },
      async showLockPasswordDialog() {
        const alert = await alertController.create({
          header: "Entrer code de verrouillage",
          inputs: [
            {
              name: "password",
              type: "password",
              placeholder: "code",
            },
          ],
          buttons: [
            {
              text: "Annuler",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Valider",
              handler: (data) => {
                if (!data.password) return;
                if (store.state.user.locked) {
                  if (store.state.user.locked === data.password) {
                    // remove lock
                    store.commit(SET_USER_PARTIAL, { locked: "" });
                    store.dispatch("saveOptions");
                  }
                } else {
                  // set password
                  store.commit(SET_USER_PARTIAL, { locked: data.password });
                  store.dispatch("saveOptions");
                }
              },
            },
          ],
        });
        return alert.present();
      },
    };
  },
});
