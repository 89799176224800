<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img
            src="assets/icon/logo.png"
            style="height: 40px"
            alt="xtenso|xsample"
          />
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-item-group>
        <ion-item-divider>
          <ion-label>Options</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label>Message de passage d’une ressource à l’autre</ion-label>
          <ion-toggle
            :checked="$store.state.user.displayConfirm"
            @ionChange="saveOptions('displayConfirm', $event.detail.checked)"
          ></ion-toggle>
        </ion-item>

        <ion-item>
          <ion-label>Rester sur la même ressource</ion-label>
          <ion-toggle
            :checked="$store.state.user.stayOnResource"
            @ionChange="saveOptions('stayOnResource', $event.detail.checked)"
          ></ion-toggle>
        </ion-item>

        <ion-item v-if="!$store.state.user.locked">
          <ion-label
            >Afficher l’activité DIVERS (permet la saisie libre du nom de
            l’activité)</ion-label
          >
          <ion-toggle
            :checked="$store.state.user.displayOther"
            @ionChange="saveOptions('displayOther', $event.detail.checked)"
          ></ion-toggle>
        </ion-item>

        <ion-item-divider>
          <ion-label>Utilisateur connecté</ion-label>
        </ion-item-divider>

        <ion-item>
          <ion-label> <b>Login:</b> {{ $store.state.user.email }} </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <b>Organisation / Groupe:</b> {{ $store.state.user.company }} /
            {{ $store.state.user.group }}
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <b>Plan:</b> {{ $store.state.user.config.plan }}
          </ion-label>
        </ion-item>
      </ion-item-group>
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-sm>
            <ion-button expand="block" @click="showLockPasswordDialog()">
              {{ $store.state.user.locked ? "Déverrouiller" : "Verrouiller" }}
            </ion-button>
          </ion-col>
          <ion-col size="12" size-sm>
            <ion-button
              v-if="!$store.state.user.locked"
              expand="block"
              @click="clearLogs()"
            >
              Supprimer données logs du cache
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  alertController,
} from "@ionic/vue";

import { useStore } from "@/store";
import { SET_USER_PARTIAL } from "@/store/mutations";

export default defineComponent({
  name: "Options",
  components: {
    IonToggle,
    IonItemGroup,
    IonItem,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItemDivider,
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
  },
  setup() {
    const store = useStore();
    return {
      saveOptions(key: string, value: boolean) {
        store.commit(SET_USER_PARTIAL, { [key]: value });
        store.dispatch("saveOptions");
      },
      async clearLogs() {
        const alert = await alertController.create({
          header: "Confirmer!",
          message: "Supprimer données logs du cache?",
          buttons: [
            {
              text: "Annuler",
              role: "cancel",
            },
            {
              text: "Supprimer",
              handler: () => {
                store.dispatch("clearLogs");
              },
            },
          ],
        });
        return alert.present();
      },
      async showLockPasswordDialog() {
        const alert = await alertController.create({
          header: "Entrer code de verrouillage",
          inputs: [
            {
              name: "password",
              type: "password",
              placeholder: "code",
            },
          ],
          buttons: [
            {
              text: "Annuler",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Valider",
              handler: (data) => {
                if (!data.password) return;
                if (store.state.user.locked) {
                  if (store.state.user.locked === data.password) {
                    // remove lock
                    store.commit(SET_USER_PARTIAL, { locked: "" });
                    store.dispatch("saveOptions");
                  }
                } else {
                  // set password
                  store.commit(SET_USER_PARTIAL, { locked: data.password });
                  store.dispatch("saveOptions");
                }
              },
            },
          ],
        });
        return alert.present();
      },
    };
  },
});
</script>
